<template>
  <finance-documents-all></finance-documents-all>
</template>

<script>
import FinanceDocumentsAll from '@/components/financeDocuments/FinanceDocumentsAll.vue';

export default {
  components: { FinanceDocumentsAll }
};
</script>

<style></style>
