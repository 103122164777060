<template>
<the-wrapper :isFullWidth="true">
  <ServicesDescription :isFooter="false">
    <template #title>
      Финансовые документы
    </template>
    <template #subTitle>
      Финансовые документы
    </template>
    <template #body>
    <h5>Пожалуйста, выберите интервал и параметры для получения документа.</h5>
    <hr />
      <b-form @submit.stop.prevent="sendRequestToCreateDocument">
        <b-row>
          <b-col xl="6">
            <b-form-group
              id="input-group-1"
              label-cols-sm="4"
              label-cols-lg="3"
              label="Вид документа"
              label-for="type-of-document"
            >
              <b-form-select
                id="type-of-document"
                v-model="form.DocumentType"
                :options="typesOfdocuments"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label-cols-sm="4"
              label-cols-lg="3"
              label="Год"
              label-for="year"
            >
              <b-form-select
                id="year"
                v-model="selectedYear"
                :options="optionsYear"
                @change="clearCustomDate"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              id="input-group-3"
              label-cols-sm="4"
              label-cols-lg="3"
              label="Месяц"
              label-for="month"
            >
              <b-form-select
                id="month"
                v-model="dateMonths"
                :options="month"
                @change="clearCustomDate"
              ></b-form-select>
              <br />
              <br />
              <p>* Или укажите произвольный диапазон дат:</p>
              <b-col sm="3">
                <label for="date-start"> С </label>
              </b-col>
              <b-col sm="9">
                <b-form-input
                  v-model="IntervalStart"
                  id="date-start"
                  type="date"
                  @change="clearMonth"
                ></b-form-input>
              </b-col>
              <b-col sm="3">
                <label for="date-end"> По </label>
              </b-col>
              <b-col sm="9">
                <b-form-input
                  v-model="IntervalEnd"
                  id="date-end"
                  type="date"
                  @change="clearMonth"
                ></b-form-input>
              </b-col>
            </b-form-group>
            <br />
            <b-form-group
              id="input-group-4"
              label-cols-sm="4"
              label-cols-lg="3"
              label="Способ получения"
              label-for="method_of_obtaining"
            >
              <b-form-select
                id="method_of_obtaining"
                v-model="form.DestinationType"
                :options="methodsOfDestinationType"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group
              id="input-group-5"
              label-cols-sm="4"
              label-cols-lg="3"
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model.trim="form.Email"
                type="email"
                required
              ></b-form-input
            ></b-form-group>
            <b-form-group
              id="input-group-6"
              label-cols-sm="4"
              label-cols-lg="3"
              label="Формат"
              label-for="file_format"
            >
              <b-form-select
                id="file_format"
                v-model="form.FileType"
                :options="fileFormats"
                required
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <BaseButton>
          <template #icon>
            <CIcon name="cilCheck"></CIcon>
          </template>
        </BaseButton>
      </b-form>
    </template>
  </ServicesDescription>
  <br>
      <base-table
        v-if="!wasReceived"
        :items="receivedDocuments"
        :fields="receivedDocumentsTitles"
        class="finance-documents__table"
      >
        <template #headerText>
          <div>Запрошенные ранее документы</div>
        </template>
        <template #cell(RequestDateTime)="{ item }">
          <p>{{ item.RequestDateTime.replace(/[T]/, ' ') }}</p>
        </template>
        <template #cell(IntervalStart)="{ item }">
          <p>{{ item.IntervalStart.replace(/[T]/, ' ') }}</p>
        </template>
        <template #cell(IntervalEnd)="{ item }">
          <p>{{ item.IntervalEnd.replace(/[T]/, ' ') }}</p>
        </template>
        <template #cell(ProcessingDateTime)="{ item }">
          <p>{{ item.ProcessingDateTime.replace(/[T]/, ' ') }}</p>
        </template>
        <template #cell(IsDeleted)="{ item }">
          <a v-if="!item.IsDeleted" @click.prevent="downloadDocument(item)" href="#">
            Скачать
          </a>
        </template>
      </base-table>
      <base-table v-else class="finance-documents__table">
        <template #headerText>
          <div>Запрошенные ранее документы</div>
        </template>
        <slot> {{ wasReceived }} </slot>
      </base-table>
  </the-wrapper>
</template>

<script>
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import BaseTable from '@/components/shared/BaseTable.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import { mapActions, mapGetters } from 'vuex';
import { getServiceData, sendCustomerRequest, downloadFile } from '@/api/common';
import { catchFunction, ShowErrorToUser, ShowSuccesToUser } from '@/additional_helpers/helpers';

import {
  URL_RECEIVED,
  URL_TYPES,
  URL_DESTIGNATION,
  URL_CREATE,
  URL_DOWNLOAD
} from '@/api/financeDocuments';
import { DaysPerMonth } from '@/additional_helpers/dates';

export default {
  ValidateError: `Заполните все данные для получения документа:
                  Вид документа, Год, Месяц (или выберите произвольный диапазон дат), Способ получения,
                  Email, Формат`,

  ERROR_500: `Что-то пошло не так. Возможно сервер не отвечает. Попробуйте позднее.
                                Если проблема наблюдается продолжительное время, сообщите нам об этом, пожалуйста, и мы в
                                кратчайшие сроки всё исправим`,

  REQUEST_SUCCESS: 'Запрос принят. В ближайшее время документ будет автоматически сформирован',

  disabledValue: { text: 'Произвольный период', value: '', disabled: true },

  components: {
    BaseTable,
    TheWrapper,
    BaseButton,
    ServicesDescription
  },

  data() {
    return {
      validate: false,
      typesOfdocuments: [],
      methodsOfDestinationType: [],
      receivedDocuments: [],
      isActive: false,
      wasReceived: '',
      baseYear: 2013,
      currentYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      IntervalStart: '',
      IntervalEnd: '',
      optionsYear: [this.$options.disabledValue],
      fileFormats: [
        { text: 'pdf', value: 'pdf' },
        { text: 'xls', value: 'xls' }
      ],
      dateMonths: '01',
      form: {
        IntervalStart: '',
        IntervalEnd: '',
        Email: '',
        DestinationType: '0',
        FileType: 'pdf',
        DocumentType: '1'
      },
      month: [
        this.$options.disabledValue,
        { text: '01 - январь', value: '01' },
        { text: '02 - февраль', value: '02' },
        { text: '03 - март', value: '03' },
        { text: '04 - апрель', value: '04' },
        { text: '05 - май', value: '05' },
        { text: '06 - июнь', value: '06' },
        { text: '07 - июль', value: '07' },
        { text: '08 - август', value: '08' },
        { text: '09 - сентябрь', value: '09' },
        { text: '10 - октябрь', value: '10' },
        { text: '11 - ноябрь', value: '11' },
        { text: '12 - декабрь', value: '12' }
      ],
      receivedDocumentsTitles: [
        { key: 'RequestDateTime', label: 'Запрошен' },
        { key: 'IntervalStart', label: 'C' },
        { key: 'IntervalEnd', label: 'По' },
        { key: 'DocumentTypeDesc', label: 'Тип' },
        { key: 'ProcessingDateTime', label: 'Обработан' },
        { key: 'StatusText', label: 'Статус' },
        { key: 'StatusDescription', label: 'Детали' },
        { key: 'Email', label: 'Email' },
        { key: 'IP', label: 'IP' },
        { key: 'FileType', label: 'Формат' },
        { key: 'IsDeleted', label: 'Скачать' }
      ]
    };
  },

  computed: {
    ...mapGetters(['financeDocuments/receivedDocuments', 'financeDocuments/requestedDocuments']),

    isValid() {
      this.calculatingDates();

      return (
        ((this.IntervalStart && this.IntervalEnd) || (this.selectedYear && this.dateMonths))
        && this.form.Email
      );
    }
  },

  created() {
    for (let i = 0; i < this.currentYear - this.baseYear + 1; i++) {
      this.optionsYear.push({
        text: this.currentYear - i,
        value: this.currentYear - i,
        disabled: false
      });
    }

    // Получает список типов документов
    getServiceData(URL_TYPES)
      .then((result) => this.typesOfdocuments.push(...result))
      .catch((e) => catchFunction(e));

    // Получает список методов доставки сформированного документа
    getServiceData(URL_DESTIGNATION)
      .then((result) => this.methodsOfDestinationType.push(...result))
      .catch((e) => catchFunction(e));

    this.getReceivedDocuments();
  },

  methods: {
    ...mapActions({
      trimingRequestData: 'financeDocuments/trimingRequestData'
    }),

    clearCustomDate() {
      if (this.selectedYear || this.dateMonths) {
        this.IntervalStart = '';
        this.IntervalEnd = '';
      }
    },

    clearMonth() {
      this.dateMonths = this.month[0].value;
      this.selectedYear = this.optionsYear[0].value;
    },

    calculatingDates() {
      const selectedMonth = this.dateMonths;
      const lastDay = DaysPerMonth(this.selectedYear, selectedMonth);

      if (this.selectedYear) {
        this.form.IntervalStart = `${this.selectedYear}-${selectedMonth}-01`;
        this.form.IntervalEnd = `${this.selectedYear}-${selectedMonth}-${lastDay}`;
        return;
      }

      if (!this.IntervalStart) {
        this.form.IntervalStart = `${this.selectedYear}-${selectedMonth}-01`;
        this.form.IntervalEnd = `${this.selectedYear}-${selectedMonth}-${lastDay}`;
      }
    },

    async deleteNotNeededKeys() {
      const requestData = {
        IntervalStart: this.IntervalStart || this.form.IntervalStart,
        IntervalEnd: this.IntervalEnd || this.form.IntervalEnd,
        Email: this.form.Email,
        DestinationType: this.form.DestinationType,
        FileType: this.form.FileType,
        DocumentType: this.form.DocumentType
      };

      await this.trimingRequestData(requestData);
    },

    async sendRequestToCreateDocument() {
      if (!this.isValid) {
        ShowErrorToUser(this.$options.ValidateError);
      } else {
        this.deleteNotNeededKeys();

        const row = JSON.stringify(this['financeDocuments/requestedDocuments']);

        await sendCustomerRequest(URL_CREATE, row)
          .then((result) => {
            if (result.status === 200) {
              ShowSuccesToUser(this.$options.REQUEST_SUCCESS);
            } else {
              ShowErrorToUser(this.$options.ERROR_500);
            }
          })
          .catch((e) => catchFunction(e));

        this.getReceivedDocuments();
      }
    },

    getReceivedDocuments() {
      getServiceData(URL_RECEIVED)
        .then((answer) => {
          if (answer.Error) {
            this.wasReceived = answer.Error;
            return;
          }
          this.receivedDocuments.push(...answer);
        })
        .catch((e) => catchFunction(e));
    },

    downloadDocument(item) {
      const row = JSON.stringify({
        Directory: item.Directory,
        FileName: item.FileName,
        FileType: item.FileType,
        DocumentType: item.DocumentType,
        IntervalStart: item.IntervalStart,
        IntervalEnd: item.IntervalEnd,
        RequestId: item.RequestId
      });

      downloadFile(URL_DOWNLOAD, row)
        .then((bytes) => {
          const elm = document.createElement('a'); // CREATE A LINK ELEMENT IN DOM
          elm.href = URL.createObjectURL(bytes.file); // SET LINK ELEMENTS CONTENTS
          elm.setAttribute(
            'download',
            `${item.IntervalStart.replace(/[T]/, ' ')} - ${item.IntervalEnd.replace(
              /[T]/,
              ' '
            )}.zip`
          ); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD. Params are after 'dowload'
          elm.click();
        })
        .catch((e) => catchFunction(e));
    }
  }
};
</script>

<style lang="scss">
.finance-documents__table {
  text-align: center;
}
</style>
